var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"pb-4",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('validation-provider',{attrs:{"rules":"min:3","name":"User ID or Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex ailgn-center"},[_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-magnify","label":"Search by User ID or Email"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":invalid || _vm.isBusy,"color":"primary","target":"_blank","type":"submit"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")]),_vm._v(" Search Users ")],1)]}}],null,true)},[_c('span',[_vm._v("Search Users")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","type":"reset","disabled":_vm.isBusy},on:{"click":function($event){$event.preventDefault();return _vm.clearFilter($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-autorenew")]),_vm._v(" Clear ")],1)]}}],null,true)},[_c('span',[_vm._v("Clear Filters")])])],1)]}}],null,true)})],1)]}}])}),_c('v-card',{staticClass:"mb-6"},[_c('v-data-table',{attrs:{"header-props":{ sortIcon: 'mdi-chevron-down' },"fixed-header":"","headers":_vm.headers,"items":_vm.users,"must-sort":true,"sort-by":"email","loading":_vm.isBusy,"items-per-page":-1,"height":"70vh"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('div',{staticClass:"action-item"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"orange","small":"","to":{ path: '/sittings', query: { sitting_user_profile_id: item.user_id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-school")])],1)]}}],null,true)},[_c('span',[_vm._v("View Sittings")])])],1),_c('div',{staticClass:"action-item"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"teal","small":"","to":{ path: '/proctoring', query: { search: item.user_id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-multiple")])],1)]}}],null,true)},[_c('span',[_vm._v("View Proctoring")])])],1),_c('div',{staticClass:"action-item"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"accent","small":"","to":{ path: '/user-events', query: { uid: item.user_id, ed: _vm.eventDate } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-group")])],1)]}}],null,true)},[_c('span',[_vm._v("View Events")])])],1),_c('div',{staticClass:"action-item"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","target":"_blank","disabled":!item.has_organisation,"href":_vm.impersonateURL(item.user_id)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Impersonate User")])])],1)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }