<template>
  <v-app>
    <p class="error" v-if="error">{{ error.message }}</p>
    <validation-observer ref="form" v-slot="{ invalid }">
      <form @submit.prevent="handleSubmit" class="pb-4">
        <validation-provider
          rules="min:3"
          name="User ID or Email"
          v-slot="{ errors }"
        >
          <div class="d-flex ailgn-center">
            <v-text-field
              :error-messages="errors"
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search by User ID or Email"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="invalid || isBusy"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  target="_blank"
                  type="submit"
                >
                  <v-icon small>mdi-magnify</v-icon>
                Search Users
                </v-btn>
              </template>
              <span>Search Users</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  type="reset"
                  :disabled="isBusy"
                  @click.prevent="clearFilter"
                >
                  <v-icon small>mdi-autorenew</v-icon>
                Clear
                </v-btn>
              </template>
              <span>Clear Filters</span>
            </v-tooltip>
          </div>
        </validation-provider>
      </form>
    </validation-observer>

    <v-card class='mb-6'>
      <v-data-table
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        fixed-header
        :headers="headers"
        :items="users"
        :must-sort="true"
        sort-by="email"
        :loading="isBusy"
        :items-per-page='-1'
        height="70vh"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions">
            <div class="action-item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    small
                    :to="{ path: '/sittings', query: { sitting_user_profile_id: item.user_id } }"
                  >
                    <v-icon small>mdi-school</v-icon>
                  </v-btn>
                </template>
                <span>View Sittings</span>
              </v-tooltip>
            </div>
            <div class="action-item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="teal"
                    v-bind="attrs"
                    v-on="on"
                    small
                    :to="{ path: '/proctoring', query: { search: item.user_id } }"
                  >
                    <v-icon small>mdi-image-multiple</v-icon>
                  </v-btn>
                </template>
                <span>View Proctoring</span>
              </v-tooltip>
            </div>
            <div class="action-item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    small
                    :to="{ path: '/user-events', query: { uid: item.user_id, ed: eventDate } }"
                  >
                    <v-icon small>mdi-account-group</v-icon>
                  </v-btn>
                </template>
                <span>View Events</span>
              </v-tooltip>
            </div>
            <div class="action-item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    small
                    target="_blank"
                    :disabled="!item.has_organisation"
                    :href="impersonateURL(item.user_id)"
                  >
                    <v-icon small>mdi-account-eye</v-icon>
                  </v-btn>
                </template>
                <span>Impersonate User</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { UsersMutations } from './../../store/users';
import { DateTime } from 'luxon';

export default {
  name: 'Users',
  computed: {
    ...mapGetters({
      users: 'users/users',
      headers: 'users/headers',
      pagination: 'users/pagination',
      isBusy: 'users/isBusy',
      error: 'users/error',
      searchKeyword: 'users/searchKeyword'
    }),
    search: {
      get () {
        return this.searchKeyword
      },
      set(value) {
        this.$store.commit(`users/${UsersMutations.SET_SEARCH_KEYWORD}`, value)
      }
    },
    eventDate() {
      return DateTime.now().toFormat('yyyy,MM,dd')
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: 'users/fetchUsers'
    }),
    clearFilter() {
      this.search = false
      this.handleSubmit()
    },
    impersonateURL(userId) {
      let appEnv = ''
      if (window.location.hostname.includes('cms-qa')) {
        appEnv = '-qa'
      } else if (window.location.hostname.includes('cms-stage')) {
        appEnv = '-stage'
      }
      return `https://dashboard${appEnv}.englishscore.com?impersonate_user_id=${userId}`
    },
    handleSubmit() {
      const query = { ... this.$route.query }
      if (!this.search) delete query.search
      else query.search = this.search

      // Pushing the route will trigger the watcher below, which calls fetchUsers().
      this.$router.push({ name: "Users", params: this.$route.params, query })
    },
  },
  watch: {
    $route: {
      handler() {
        const { search } = this.$route.query
        this.search = search

        this.fetchUsers()
      },
      immediate: true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  text-transform: initial;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field {
    margin: 0px;
    padding: 0px;
    .v-label {
      z-index: 100;
    }
  }
  .v-text-field__details {
    display: none;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-data-footer__select {
    visibility: hidden;
  }
}
</style>